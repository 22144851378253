import React from 'react';
import { ModalDescription, ModalTitle } from '../Typography';

function ModalHeading({ title, subtitle }) {
  return (
    <div className="heading__container">
      <ModalTitle>{title}</ModalTitle>
      <ModalDescription>{subtitle}</ModalDescription>
    </div>
  );
}

export default ModalHeading;
