import React from 'react';
import ModalHeading from './ModalHeading';
import { PopupButton, useCalendlyEventListener } from 'react-calendly';
import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { MAIN_BUTTON_CLASSNAME } from '../../constants';

function InfoModal({ handleClick }) {
  const imageData = useStaticQuery(graphql`query InfoImage {
  file(relativePath: {eq: "illustrations/step-3-remortgage-modal.png"}) {
    childImageSharp {
      gatsbyImageData(width: 128, height: 120, quality: 90, layout: FIXED)
    }
  }
}`)

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      let eventUuid = e.data.payload.event.uri.split('/');
      eventUuid = eventUuid[eventUuid.length - 1];

      handleClick(eventUuid);
    },
  });

  const prefill = useMemo(
    () => ({
      email: localStorage.getItem('email') || '',
      name: localStorage.getItem('name') || '',
      location: "+44" + localStorage.getItem('phone') || '',
      customAnswers: {
        a1: "44" + localStorage.getItem('phone') || ''
      }
    }),
    []
  );

  return (
    <div className="info-modal">
      <GatsbyImage image={imageData?.file?.childImageSharp?.gatsbyImageData} />
      <div className="info-modal__heading-container">
        <ModalHeading
          title="Awesome!"
          subtitle={(<>
                    Save time by speaking to our expert.
                    <br />
                    They’ll fill in the paperwork and do all 
                    the heavy lifting for you.
            </>)}
        />
      </div>
      <div className="info-modal__button-container">
        <div className="button__wrapper">
          <PopupButton
            url="https://calendly.com/teamsprive/chat-to-our-expert-web"
            rootElement={document.getElementById('___gatsby')}
            text="Book an appoinment"
            className={`${MAIN_BUTTON_CLASSNAME} button__size-modal`}
            prefill={prefill}
          />
        </div>
      </div>
    </div>
  );
}

export default InfoModal;
